"use strict";
window.onload = function()
{
    var emulator = window.emulator = new V86Starter({
        memory_size: 32 * 1024 * 1024,
        vga_memory_size: 2 * 1024 * 1024,
        screen_container: document.getElementById("screen"),
        bios: {
            url: "./seabios.bin",
        },
        vga_bios: {
            url: "./vgabios.bin",
        },
        cdrom: {
            url: "./linux.iso",
        },
        autostart: true,
    });
}